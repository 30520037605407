/* src/pages/CastingPage.css */
.casting-page {
  font-family: 'Tajawal', sans-serif;
  background-color: #121212;
  color: #ffffff;
  padding-bottom: 20px;
}

.casting-page.ltr {
  text-align: left;
}

.

.casting-page.rtl {
  text-align: right;
}

.casting-page .hero-image {
  background-image: url('../assets/casting/castinghero.jpg'); /* Ensure the path is correct */
  background-size: cover;
  background-position: center;
  color: white;
  padding: 200px 0;
  text-align: center;
}

.casting-page .hero-image h1, 
.casting-page .hero-image p {
  font-family: 'Raleway', sans-serif !important; /* Apply Raleway font */
}

.casting-page.rtl .hero-image h1, 
.casting-page.rtl .hero-image p {
  font-family: 'Tajawal', sans-serif !important; /* Apply Tajawal font for RTL */
}

/* Form Styles */
.casting-page .form-group {
  margin-bottom: 20px;
}

.casting-page .form-label {
  font-weight: bold;
  font-size: 1.1em;
}

.casting-page .form-control {
  background-color: #2c2c2c;
  color: white;
  border: 1px solid #444;
}

.casting-page .form-control::placeholder {
  color: #888;
}

.casting-page .form-control:focus {
  border-color: #007bff;
  box-shadow: none;
}

.casting-page .form-select {
  background-color: #2c2c2c;
  color: white;
  border: 1px solid #444;
}

.casting-page .form-select:focus {
  border-color: #007bff;
  box-shadow: none;
}

/* Photo Previews */
.photo-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-container {
  position: relative;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.preview-container button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 5px;
  font-size: 12px;
}

/* Buttons */
.button {
  background-color: #007bff;
  color: rgb(0, 0, 0);
  border: none;
}

.button:hover {
  background-color: #0056b3;
}

/* Alerts */
.alert-success {
  background-color: #28a745;
  color: white;
}

.alert-danger {
  background-color: #dc3545;
  color: white;
}

/* Modal */
.modal-content {
  border-radius: 10px;
  background-color: #2c2c2c;
  color: white;
}

.modal-header {
  background-color: #343a40;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
}

.modal-body {
  padding: 20px;
}

.modal-body p {
  margin-bottom: 10px;
}

.modal-body h5 {
  margin-top: 20px;
}

.modal-body a {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #007bff;
}

.modal-body a:hover {
  text-decoration: underline;
}

.modal-footer {
  border-top: none;
  justify-content: center;
}

/* RTL Support */
.casting-page.rtl .form-control,
.casting-page.rtl .form-label {
  text-align: right;
}
