.blog-section {
  padding: 80px 20px;
  background-color: #000000;
  color: #f9fafb;
}

.rtl {
  direction: rtl;
  font-family: 'Tajawal', sans-serif;
}

.ltr {
  direction: ltr;
  font-family: 'Raleway', sans-serif;
}

.section-header {
  font-size: 150px;
  font-weight: bold;
  margin-top: 122px;
}

.section-subheader {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 40px;
}

.blog-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  height: 100%; /* Ensure the card takes full height for uniformity */
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.blog-card .card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-card .card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.blog-card .card-text {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 20px;
  flex-grow: 1; /* Ensure the text area grows to take available space */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.blog-card .btn {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  align-self: flex-start; /* Align button to the start */
}

@media (max-width: 1200px) {
  .section-header {
    font-size: 60px;
  }
}

@media (max-width: 992px) {
  .section-header {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .blog-section {
    padding: 60px 15px;
  }

  .section-header {
    font-size: 40px;
  }

  .section-subheader {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .blog-card .card-title {
    font-size: 22px;
  }

  .blog-card .card-text {
    font-size: 14px;
  }

  .blog-card .btn {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .blog-section {
    padding: 40px 10px;
  }

  .section-header {
    font-size: 32px;
    margin-top: 80px;
  }

  .section-subheader {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .blog-card .card-title {
    font-size: 20px;
  }

  .blog-card .card-text {
    font-size: 12px;
  }

  .blog-card .btn {
    font-size: 12px;
  }
}
