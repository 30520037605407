/* src/components/Works.css */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Tajawal:wght@400;700&display=swap');

.works-section {
  padding: 80px 0;
  background-color: #040404;
  text-align: center;
}

.works-section h2 {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 80px;
  font-family: 'Raleway', sans-serif;
  color: #fff;
  margin-top: 120px;
}

.poster-col {
  margin-bottom: 20px;
}

.poster-container {
  position: relative;
}

.poster {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.poster:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 64px;
  color: white;
  opacity: 0.7;
  pointer-events: none; /* Ensures the click event is registered on the poster image */
}

.show-more {
  margin-top: 20px;
}

.show-more button {
  margin-top: 40px;
  padding: 10px 20px;
  font-size: 24px;
  color: rgb(255, 251, 251);
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Raleway', sans-serif;
}

.show-more button:hover {
  background-color: #ffffff;
  color: #110000;
}

.video-modal {
  padding: 0;
  position: relative; /* Ensure close button is correctly positioned */
}

/* Update the size of the video in the modal */
.video-modal iframe {
  width: 100%; /* Full width of the modal */
  height: 38.25vw; /* Aspect ratio 16:9; adjust height based on width */
  max-width: 100%;
  max-height: 100%;
  display: block;
}

/* Style for the close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 27px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1050; /* Ensure it is above other elements */
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Style for the modal dialog to ensure it is wide enough */
.video-modal-dialog {
  max-width: 90vw; /* Adjust as needed */
  max-height: 80vh; /* Adjust as needed */
}

/* Responsive styles */
@media (max-width: 768px) {
  .poster {
    margin-bottom: 10px;
  }

  .video-modal iframe {
    height: 50vw; /* Adjust height based on smaller width */
  }
}

@media (max-width: 576px) {
  .poster {
    margin-bottom: 15px;
  }

  .works-section h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 80px;
    font-family: 'Raleway', sans-serif;
    color: #fff;
  }

  .video-modal iframe {
    height: 60vw; /* Adjust height for very small screens */
  }
}
