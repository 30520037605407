@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Tajawal:wght@400;700&display=swap");

.director-container {
  background-color: #000;
  color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.director-container.ltr {
  font-family: "Raleway", sans-serif;
  direction: ltr;
  text-align: left;
}

.director-container.rtl {
  font-family: "Tajawal", sans-serif;
  direction: rtl;
  text-align: right;
}

.director-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  animation: fadeIn 2s ease-in-out;
}

.director-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  animation: zoomIn  ;
}

.director-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.director-title {
  font-weight: 700;
  font-size: 4rem;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.director-subtitle {
  font-weight: 400;
  font-size: 1.5rem;
}

.director-content {
  padding: 60px 20px;
  background-color: #111;
}

.director-section {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}
.director-section.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.director-section h2 {
  text-align: center;
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 50px;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  width: fit-content;
}

/* Text Sections */
.director-section p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ddd;
  text-align: center;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #222;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Headings inside the text sections */
.director-section h3 {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #fff;
}

/* Specific Section Styles */


.bio-card {
  display: flex;
  flex-direction: column; /* Align image and text side by side */
  align-items: center;
  background: #222;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-top: 30px;
}


.bio-text {
  flex: 1;
  width: 100%; /* Ensure text takes full width */
  margin-bottom: 20px; /* Add margin at the bottom for space between text and image */
  padding: 20px 0;
}

.bio-text p {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.8;
  text-align: justify;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px 50px;

}

.bio-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin-top: 10px; /* Add margin above the image to separate from the text */
  align-self: center; /* Center the image in the flexbox column */
}

.show-more-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px; /* Adds space above the button */
  border-radius: 5px;
  width: auto;
  text-align: center; /* Centers the button text */
  align-self: flex-start; /* Align the button to the start of the container */
}

.show-more-button:hover {
  background-color: #0056b3;
}

.awards,
.filmography,
.biography {
  margin-bottom: 50px;
  width: 100%;
  max-width: 1200px;
}

.bio-card p {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.6;
}

/* Ensures all awards are uniform in size and appearance */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Change to display 3 items per row */
  gap: 20px;
  animation: fadeInUp 2s ease-in-out;
  width: 100%;
  max-width: 1200px; /* Ensure grid does not exceed max width */
  margin: 0 auto; /* Center the grid container */
}


.grid-item {
  background: #222;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%; /* Make sure all grid items have equal height */
  max-width: 450px; /* Increase this to make the grid items bigger */
  margin: 0 auto; /* Center each grid item */
}







/* Ensure all award images are the same size */
.award img {
  width: 100%;
  height: 150px; /* Fixed height for all award images */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Style for the text inside the award card */
.award-info h3 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
}

.award-info p {
  font-size: 1rem;
  color: #ccc;
}

.grid-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.poster-info,
.award-info {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
}



.poster-info h3,
.award-info h3 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .bio-card {
    flex-direction: column;
    align-items: center;
  }

  .bio-text {
    margin-right: 0;
    margin-bottom: 20px;
    padding: 20px 0;
  }

  .bio-text p {
    font-size: 1rem;
    line-height: 1.8; /* Adjust line-height for smaller screens */
    padding: 5px 0; /* Adjust padding for smaller screens */
  }

  .bio-image {
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .director-content {
    padding: 40px 20px;
  }

  .director-section h2 {
    font-size: 2rem;
    margin-top: 50px;
  }

  .director-hero {
    height: 50vh; /* Set a smaller height for mobile screens */
  }

  .bio-card p {
    font-size: 1rem;
  }

  .grid-item {
    padding: 15px;
  }

  .award img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 150px; /* Adjust this to your desired width */
    max-height: 150px; /* Adjust this to your desired height */
    display: block;
    margin: 0 auto;
}

  .poster-info h3,
  .award-info h3 {
    font-size: 1rem;
  }



  .poster-info p,
  .award-info p {
    font-size: 0.875rem;
  }
}

@media (max-width: 576px) {
  .director-title {
    font-size: 2.5rem;
  }

  .director-subtitle {
    font-size: 1rem;
  }

  .director-section h2 {
    font-size: 1.5rem;
  }

  .bio-card {
    padding: 10px;
  }

  .bio-text p {
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}

  .grid-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .grid-item {
    padding: 10px;
    max-width: 100%; /* Use full width for mobile */
  }

  .award img {
    height: 100px; /* Smaller height for very small screens */
  }

  .award-info h3 {
    font-size: 0.875rem;
  }

  .award-info p {
    font-size: 0.75rem;
  }

  .poster-info h3,
  .award-info h3 {
    font-size: 1rem;
  }

  .poster-info p,
  .award-info p {
    font-size: 0.875rem;
  }
}
