@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Tajawal:wght@400;700&display=swap');

.hero-section {
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-text {
  font-size: 48px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

.script-submission-container {
  font-family: 'Tajawal', sans-serif;
  margin-top: 50px;
}

.script-submission-container.rtl {
  direction: rtl;
  text-align: right;
}

.script-submission-container.ltr {
  direction: ltr;
  text-align: left;
}

.text-center {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
}

.rtl .text-center {
  font-family: 'Tajawal', sans-serif;
}

.intro-text {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 900;
}

.form-label, .form-control {
  font-family: 'Tajawal', sans-serif;
}

.rtl .form-label, .rtl .form-control {
  text-align: right;
}

.ltr .form-label, .ltr .form-control {
  text-align: left;
}

.btn-primary {
  font-family: 'Raleway', sans-serif;
  background-color: #007bff;
  border: 2px solid black; /* Added black border */
}

.rtl .btn-primary {
  font-family: 'Tajawal', sans-serif;
  background-color: #007bff;
  border: 2px solid black; /* Added black border */
}

.rtl .form-control::placeholder {
  text-align: right;
}

.ltr .form-control::placeholder {
  text-align: left;
}

.button {
  margin-bottom: 50px;
  background-color: #007bff;
  border: 2px solid black; /* Added black border */
}
