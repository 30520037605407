/* src/components/LoginPage.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000; /* Black background */
  padding-top: 80px; /* Pushes the login box down to avoid overlap with the navbar */
  width: 100%;
}

.login-box {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-box h1 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
}

.login-box .form-group {
  margin-bottom: 20px;
}

.login-box .form-control {
  border-radius: 5px;
  padding: 10px;
}

.login-box .btn {
  border-radius: 5px;
}

.alert {
  margin-top: 20px;
}

/* Style for password field with eye icon */
.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .login-box {
    padding: 20px;
  }

  .login-box h1 {
    font-size: 1.5rem;
  }
}
