/* Your original styles */
.about-section {
  padding-top: 50px;
  background-color: black;
  color: white;
  text-align: center;
}

.about-text h2 {
  font-size: 50px;
  font-weight: 600;
  padding: 50px;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 18px;
  margin-top: 20px;
  line-height: 1.6;
  text-align: justify;
  direction: ltr;
}

/* When the text is in Arabic */
.about-text.rtl p {
  direction: rtl;
}

.about-image {
  margin-top: 320px;
  width: 140%;
  border-radius: 10px;
}

/* Button container */
.button-container {
  display: flex;
  width: 100%;
}

/* Align button to the right for LTR languages */
.button-right {
  justify-content: flex-end;
}

/* Align button to the left for RTL languages */
.button-left {
  justify-content: flex-start;
}

/* Styling for the "Read More" button */
.read-more-btn {  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.read-more-btn:hover {
  background-color: white;
  color: black;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-section {
    padding: 0px 0;
  }

  .about-text h2 {
    font-size: 28px;
    margin-top: 25px;
  }

  .about-text p {
    font-size: 16px;
    line-height: 1.6;
    word-spacing: 0;
  }

  .about-text.rtl p {
    text-align: right;
    line-height: 1.6;
    word-spacing: 0;
  }

  .about-image {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
  }
}
