.hero-container {
   height: 90vh;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   text-align: center;
   overflow: hidden;
}

.hero-container::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to bottom, rgba(217, 12, 12, 0) 0%, #000 100%);
   z-index: -1;
}

.hero-video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
   z-index: -2;
}

.hero-text {
   padding: 20px;
   border-radius: 10px;
   z-index: 1;
   margin-bottom: 10px;
}

.hero-text h1 {
   font-size: 50px;
   margin-bottom: 20px;
   font-weight: bold;
}

.hero-text p {
   font-size: 24px;
   margin-bottom: 0;
}

.sound-toggle {
   position: absolute; /* Position relative to the hero-container */
   bottom: 20px; /* Distance from the bottom of the video */
   left: 20px; /* Distance from the left of the video */
   z-index: 2; /* Ensure it is above the video but below the text */
   background-color: rgba(0, 0, 0, 0.7);
   border: none;
   cursor: pointer;
   padding: 10px;
   border-radius: 50%;
   color: white;
   font-size: 24px;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: background-color 0.3s ease, transform 0.3s ease;
}

.sound-toggle:hover {
   background-color: rgba(3, 0, 0, 0.9);
   transform: scale(1.1);
}

@media (max-width: 768px) {
   .hero-container {
      height: 95vh;
   }

   .hero-video {
      object-fit: cover; /* Keep the video covering the entire container */
      height: auto;
   }

   .hero-text {
      padding-bottom: 20px;
      border-radius: 5px;
      margin-top: 200px;
   }

   .hero-text h1 {
      font-size: 40px;
      
   }

   .hero-text p {
      font-size: 18px;
   }

   .sound-toggle {
      bottom: 50px; /* Keep it 20px from the bottom */
      left: 20px; /* Keep it 20px from the left */
      font-size: 20px;
      padding: 8px;
   }
}
