@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Tajawal:wght@400;700&display=swap');

.container {
  font-family: 'Tajawal', sans-serif;
}

.text-center h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 75px;
  margin-bottom: 20px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Center the modal and make it responsive */
.modal-content {
  padding: 0;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.modal-header {
  border-bottom: none;
  padding: 1rem 1rem 0 1rem;
}

.modal-body {
  padding: 0;
}

@media (max-width: 576px) {
  .modal-dialog {
    margin-right: 1rem;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  .text-center h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
  }
}

.card-title {
  font-family: 'Tajawal', sans-serif;
  font-weight: 700;
}

.card-text {
  font-family: 'Tajawal', sans-serif;
}

.testimonials-content.rtl .card-title,
.testimonials-content.rtl .card-text {
  font-family: 'Tajawal', sans-serif;
  direction: rtl;
  text-align: right;
}

.testimonials-content.ltr .card-title,
.testimonials-content.ltr .card-text {
  font-family: 'Raleway', sans-serif;
  direction: ltr;
  text-align: left;
}
