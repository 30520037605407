/* src/components/Showreel.css */
.showreel-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center;
  height: 110vh; /* Full height of the viewport */
  background-color: #fffefe; /* Background color for the container */
  padding: 90px; /* Padding to prevent the video from touching the edges on small screens */
  box-sizing: border-box;
}

.showreel-text {
  text-align: center;
  color: rgb(0, 0, 0); /* Text color */
  margin-bottom: 20px; /* Space between text and video */
}

.smalltex{
  margin-top: 20px;
}

.title {
  font-size: 40px;
  font-weight: 900;
}

.showreel-video {
  width: 65%;
  height: 150%;

  max-width: 1200px; /* Maximum width for larger screens */
  border: 2px solid #fff; /* Optional border styling */
  border-radius: 10px; /* Optional border radius for aesthetics */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics */
}

.showreel-thumbnail-container {
  position: relative;
  cursor: pointer;
}

.showreel-thumbnail {
  width: 800px;
  height: auto;
  display: block;
  border-radius: 10px; /* Optional border radius for aesthetics */

}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.play-button img {
  width: 100px;
  height: 100px;
}



/* Additional media query for small screens */
@media (max-width: 768px) {
  .showreel-container {
      padding: 10px; /* Reduce padding for smaller screens */
      height: 400px; /* Adjust height for smaller screens */
  }

  .showreel-text {
      font-size: 24px; /* Adjust font size for small screens */
      margin-top: 50px; /* Adjust space between text and video */
  }

  .title {
      font-size: 28px;
  }

  .showreel-video {
      border: 1px solid #fff; /* Slightly smaller border */
      width: 100%;

  }
}

/* Additional media query for extra small screens */
@media (max-width: 480px) {
  .showreel-container {
      padding: 10px; /* Reduce padding for very small screens */
      height: 400px; /* Adjust height for very small screens */
  }

  .showreel-text {
      font-size: 20px; /* Adjust font size for extra small screens */
      margin-bottom: 20px; /* Adjust space between text and video */
  }

  .title {
      font-size: 24px;
  }

  .showreel-video {
      border: 1px solid #fff; /* Slightly smaller border */
  }

  .showreel-thumbnail {
    width: 400px;
    height: auto;
    display: block;
  }

  .play-button img {
    width: 50px;
    height: 50px;
  }
}
