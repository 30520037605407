@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700;800&display=swap');

.casting-container {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150vh;
  position: relative;
  overflow: hidden;
}

.casting-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; /* Make the background cover the full viewport height */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.casting-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
}

.casting-content h1,
.casting-content h2 {
  padding: 10px 0;
}

.casting-content.ltr h1,
.casting-content.ltr h2 {
  font-family: 'Raleway', sans-serif;
}

.casting-content.rtl h1,
.casting-content.rtl h2 {
  font-family: 'Tajawal', sans-serif;
}

.casting-content h1 {
  margin-top: 380px; /* Adjust this to move h1 down */
  font-size: 75px;
  font-weight: 800; /* Extra bold */
}

.casting-content h2 {
  font-size: 75px;
  font-weight: normal;
}

.casting-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 24px;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.casting-button:hover {
  background-color: white;
  color: black;
}

/* New section styles */
.script-submission {
  margin-top:30px; /* Space between existing content and new section */
}

.script-submission h3 {
  font-size: 50px; /* Adjust size for new section header */
  font-weight: 700; /* Bold */
  margin-bottom: 20px; /* Space below the header */
}

.script-submission p {
  font-size: 20px; /* Adjust text size for the paragraph */
  margin-bottom: 20px; /* Space below the paragraph */
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .casting-content h1 {
    font-size: 60px;
  }

  .casting-content h2 {
    font-size: 24px;
  }

  .casting-content .script-submission h3 {
    font-size: 40px;
  }

  .casting-content .script-submission p {
    font-size: 18px;
  }

  .casting-button {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .casting-content h1 {
    font-size: 50px;
    margin-top: 300px;
  }

  .casting-content h2 {
    font-size: 20px;
  }

  .casting-content .script-submission h3 {
    font-size: 35px;
  }

  .casting-content .script-submission p {
    font-size: 16px;
  }

  .casting-button {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .casting-content h1 {
    font-size: 40px;
    margin-top: 250px;
  }

  .casting-content h2 {
    font-size: 18px;
  }

  .casting-content .script-submission h3 {
    font-size: 30px;
  }

  .casting-content .script-submission p {
    font-size: 14px;
  }

  .casting-button {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .casting-content h1 {
    font-size: 35px;
    margin-top: 600px;
  }

  .casting-content h2 {
    font-size: 16px;
  }

  .casting-content .script-submission h3 {
    font-size: 25px;
  }

  .casting-content .script-submission p {
    font-size: 12px;
  }

  .casting-button {
    font-size: 14px;
    padding: 6px 12px;
  }
}
